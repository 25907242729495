<template>
    <div class="container">
        <definition></definition>
    </div>
</template>

<script>

    export default {
        components:{
            definition: () => import('../components/DefinitionTypes/index.vue')
        }
    }
</script>

<style scoped>

</style>